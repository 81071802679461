import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";

const AboutLandscaping = () => {
  //   Fencing
  // Decking
  // Patios
  // Artificial and natural grass laying
  // Sunken hot tub installation
  // Bespoke pergola installation
  // Garden lighting

  const features = [
    "Fencing",
    "Decking",
    "Patios",
    "Artificial and natural grass laying",
    "Sunken hot tub installation",
    "Bespoke pergola installation",
    "Garden lighting",
  ];
  return (
    <section id="intro">
      <div className="container">
        <div className="grid">
          <div className="grid__1 pb-none grid--75">
            <h2>
              Cardiff Garden Rooms offer a wide range of landscaping services.
            </h2>
          </div>
          <div className="grid__2">
            <p>
              We love transforming gardens into something special for customers
              to enjoy, if you need help with your garden, we have you covered,
              services include;
            </p>
            <ul className="leaf">
              {features.map((feat, i) => {
                return <li key={i}>{feat}</li>;
              })}
            </ul>
            <br />
            <StaticImage
              src="../assets/images/cpa.png"
              alt="Cardiff Property Swards winner"
              width={230}
              height={113}
            />
          </div>
          <div className="grid__2 hide-mob">
            <StaticImage
              src="../assets/images/landscaping.jpg"
              alt="Cardiff Garden Rooms"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutLandscaping;
