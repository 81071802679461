import React from "react";
import "normalize.css";
import HeadStuff from "../components/HeadStuff.js";
import Header from "../components/Header.js";
import AboutLandscaping from "../components/AboutLandscaping.js";
import Testimonials from "../components/Testimonials.js";
import GlobalStyles from "../styles/GlobalStyles.js";
import Gallery from "../components/Gallery.js";
import Contact from "../sections/Contact.js";
import FloatingWhatsapp from "../components/FloatingWhatsapp.js";
import { Helmet } from "react-helmet";

const index = () => {
  return (
    <>
      <HeadStuff
        title="Cardiff Garden Rooms: Landscaping"
        description="Cardiff Garden Rooms build bespoke, insulated garden rooms to suit your requirements. If you are looking for a home office, gym or living space, we can help."
      />

      <GlobalStyles />
      <Header heroTitle={"Landscaping Services"} />
      <AboutLandscaping />
      <Testimonials />
      <Contact />
      <FloatingWhatsapp />
    </>
  );
};

export default index;
